@use "../../public/styles/variables.scss";

body main .block.FAQs {
  padding-top: 0;

  h2 {
    margin-bottom: 40px;
  }

  a {
    color: variables.$link;
    text-decoration: none;
  }

  .faq {
    display: flex;
    flex-direction: column;
    padding: 16px 40px;
    background: variables.$neutral_03;
    color: variables.$text_01;
    border-radius: 8px;
    margin-bottom: 8px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      @include variables.P2Bold;

      .close {
        height: 24px;

        svg {
          transform: rotate(45deg) scale(0.9);
          transition: transform 0.2s ease-in-out;
          fill: variables.$link;
        }
      }
    }

    .description {
      margin-top: 8px;
      display: none;
      
      p {
        @include variables.P2;
        margin-bottom: 30px;
        
        &:last-of-type {
          margin-bottom: 0px;
        }
      }
    }

    &.open {
      .title .close svg {
        transform: rotate(0deg);
      }

      .description {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  body main .block.FAQs h2 {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 650px) {
  body main .block.FAQs .faq {
    padding: 16px;

    .title {
      @include variables.P3Bold;
      align-items: flex-start;
    }

    .description p {
      @include variables.P4;
    }
  }
}