body main .block.FAQs {
  padding-top: 0;
}

body main .block.FAQs h2 {
  margin-bottom: 40px;
}

body main .block.FAQs a {
  color: #536ced;
  text-decoration: none;
}

body main .block.FAQs .faq {
  color: #232735;
  background: #eaf1ff;
  border-radius: 8px;
  flex-direction: column;
  margin-bottom: 8px;
  padding: 16px 40px;
  display: flex;
}

body main .block.FAQs .faq .title {
  cursor: pointer;
  font-family: CirceBold;
  font-weight: initial;
  -webkit-font-smoothing: antialiased;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  line-height: 32px;
  display: flex;
}

body main .block.FAQs .faq .title .close {
  height: 24px;
}

body main .block.FAQs .faq .title .close svg {
  fill: #536ced;
  transition: transform .2s ease-in-out;
  transform: rotate(45deg)scale(.9);
}

body main .block.FAQs .faq .description {
  margin-top: 8px;
  display: none;
}

body main .block.FAQs .faq .description p {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

body main .block.FAQs .faq .description p:last-of-type {
  margin-bottom: 0;
}

body main .block.FAQs .faq.open .title .close svg {
  transform: rotate(0);
}

body main .block.FAQs .faq.open .description {
  display: block;
}

@media only screen and (width <= 768px) {
  body main .block.FAQs h2 {
    margin-bottom: 24px;
  }
}

@media only screen and (width <= 650px) {
  body main .block.FAQs .faq {
    padding: 16px;
  }

  body main .block.FAQs .faq .title {
    font-family: CirceBold;
    font-weight: initial;
    -webkit-font-smoothing: antialiased;
    align-items: flex-start;
    font-size: 16px;
    line-height: 24px;
  }

  body main .block.FAQs .faq .description p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
}
/*# sourceMappingURL=faq.8bfb6f2e.css.map */
